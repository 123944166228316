<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-2 align-items-center justify-content-start">
            <div class="">
                <img style="width:4rem; margin-right:1rem;" :src="absoluteUrl(item.image)" />
            </div> 
            <div class="">
                <h5 class="mb-0 card-title">${{item.price}}</h5>
                <span><span v-if="item.category">{{item.category.title}}</span> {{item.title}}</span>
            </div>
        </div>
        <p class="card-text small">{{item.description}}</p>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>
</template>
<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>