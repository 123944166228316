<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-12">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Category <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.category_id"
                name="category_id" placeholder="--select type--">
                    <option disabled value="">--select type--</option>
                    <option v-for="category in categories" :key="category.id"
                        :value="category.id">{{category.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.category_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-6">
                <label for="inputPrice">
                    Price <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.price" class="form-control"
                name="price" id="inputPrice" :class="{ 'is-invalid': v$.data.price.$error }" placeholder="Enter Price" />
                <div v-for="(item, index) in v$.data.price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">
                    Slug <span class="text-danger">*</span>
                </label>
                <input type="text" v-model="data.slug" class="form-control"
                    name="slug" :class="{ 'is-invalid': v$.data.slug.$error }" placeholder="Enter slug" />
                <div v-for="(item, index) in v$.data.slug.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-12">
                <label class="form-label">
                    Description
                </label>
                <textarea v-model="data.description" class="form-control" rows="2"
                    placeholder="Write description" name="description"></textarea>
            </div>  
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                    Active
                </b-form-checkbox>
            </div>         
            <div class="col-12">
                <vue-dropzone id="repairUploadDrop" ref="repairUploadDrop"
                :use-custom-slot="true" :maxFiles="1">
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> {{editMode?'Add file to update':'Repair image'}}</h6>
                    <span class="text-muted fs-13">
                        (Image file is recommended (160x120 pixels))
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "../VueDropzone.vue";

const validateSlug = (value) => {
    if (!value) return true; 
    return /^\w+(?:-\w+)*$/.test(value);
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                title:"",
                category_id: "",
                slug:"",
                price: "",
                is_active: true,
                description: "",
            },
        }
    },
    watch: {
        'data.title': function (_){
            if(this.editMode) return;
            this.formSlug();
        },
        'data.category_id': function (_){
            this.formSlug();
        },
    },
    validations() {
        return {        
            data:{
                title: { required: required},            
                price: { required: required},            
                category_id: { required: required},            
                slug: {
                    required,
                    validateSlug: helpers.withMessage("Enter a valid slug format (alpha-numeric)", validateSlug),
                },           
            }
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                if(!this.collectFiles().length){ this.alertError("Please a add image file"); return;}
                let formData = new FormData();
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/repairs/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("repair/ADD_REPAIR", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/repairs/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("repair/UPDATE_REPAIR", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        formSlug(){
            const category = this.categories.find(t => t.id == this.data.category_id)
            if(!category) return;

            this.data.slug = category.slug +'-'+ this.$filters.slug(this.data.title)
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.repairUploadDrop){
                arrafile = [...this.$refs.repairUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>